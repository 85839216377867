import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupPaycodeForExtraPaymentItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPaycodesForExtraPaymentService extends IcServiceBase<IcLookupPaycodeForExtraPaymentItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.PaycodeForEp;

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupPaycodeForExtraPaymentItemDto[] {
    return lookup.paycodesForExtraPayment;
  }
}
