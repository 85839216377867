import { IIcLookupTaxUnitItemDto, IcLookupTaxUnitItemDto } from '@data-access/bulk-operations-api';

import { createIcServiceMock } from './item-cache-service.base.mock';

const icTaxUnits: IcLookupTaxUnitItemDto[] = [
  IcLookupTaxUnitItemDto.fromJS({
    data: {
      name: 'Tax unit #1',
      organizationNumber: '843456782',
    },
    version: 1,
    id: 'id-tax-unit-1',
  } as IIcLookupTaxUnitItemDto),
];

export function getIcTaxUnitsServiceMock() {
  return createIcServiceMock(icTaxUnits);
}
