import { Injectable } from '@angular/core';
import {
  IcLookupCostUnitTypeItemDto,
  IcLookupDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcCostUnitTypesService extends IcServiceBase<IcLookupCostUnitTypeItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.CostUnitType;

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupCostUnitTypeItemDto[] {
    return lookup.costUnitTypes;
  }
}
