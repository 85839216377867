import { Injectable, effect, inject, untracked } from '@angular/core';
import {
  IcLookupDto,
  IcLookupEmployeeItemDto,
  IcLookupPositionPayDto,
  IcLookupTypeEnumDto,
  IcLookupWtaItemDto,
} from '@data-access/bulk-operations-api';
import {
  WsIcUpsertedEmployeeItem,
  WsIcUpsertedPositionItem,
} from '@data-access/bulk-operations-ws';
import { WsServerPayloadEnum } from '@data-access/bulk-operations-ws';
import { calculateSalariesUsingIcLookupPositionPayDto, getTimelineItemAtDate } from '@em-helpers';
import { delayWhen, filter, iif, of, take } from 'rxjs';

import { IcServiceBase } from './ic-service.base';
import { IcWorkTimeAgreementsService } from './ic-work-time-agreements.service';

@Injectable({ providedIn: 'root' })
export class IcEmployeesService extends IcServiceBase<IcLookupEmployeeItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.Employee;

  private readonly icWorkTimeAgreements = inject(IcWorkTimeAgreementsService).valuesMap;

  protected constructor() {
    super();
    effect(() => {
      // Need to call wta signal here to trigger effect
      const wtas = this.icWorkTimeAgreements();
      untracked(() => {
        this.rawValues.update((values) => {
          values.forEach((item) => {
            this.updateSalaries(item, wtas);
          });

          return new Map(values);
        });
      });
    });
  }

  public override startSync(employeeId?: string) {
    this.client.getIcStartSync(this.lookupType, employeeId).pipe(take(1)).subscribe();
  }

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupEmployeeItemDto[] {
    return lookup.employees;
  }

  public override setCache(lookupValues: IcLookupEmployeeItemDto[], isInitialization = false) {
    this.rawValues.set(
      new Map(
        lookupValues.map((item) => {
          this.updateSalaries(item, this.icWorkTimeAgreements());

          return [item.id, item];
        }),
      ),
    );
    if (!isInitialization) {
      this.isInitialized.set(true);
    }
  }

  public override upsertCacheItems(newValues: IcLookupEmployeeItemDto[]) {
    this.rawValues.update((values) => {
      newValues.forEach((newValue) => {
        const currentValue = values.get(newValue.id);

        // We will remove version if there are no errors
        if (currentValue && currentValue.version > newValue.version) {
          console.error(
            `ItemCache: [${this.lookupType}] received WS message with out-of-date version. ID: ${newValue.id}, v: ${newValue.version} (v: ${currentValue.version})`,
          );
        }

        this.updateSalaries(newValue, this.icWorkTimeAgreements());

        values.set(newValue.id, newValue);
      });

      return new Map(values);
    });
  }
  public override upsertCacheItem(newValue: IcLookupEmployeeItemDto) {
    this.upsertCacheItems([newValue]);
  }

  protected override subscribeToWsUpsert() {
    this.wsUpsertSubscription?.unsubscribe();

    this.wsUpsertSubscription = this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Employee),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        // TODO: optimize if we will start getting batched messages
        msg.employeeItems?.forEach((item) => this.handleEmployeeUpsert(item));
        msg.positionItems?.forEach((item) => this.handlePositionUpsert(item));
      });
  }

  private handleEmployeeUpsert(msg: WsIcUpsertedEmployeeItem) {
    const currentValue = this.valuesMap().get(msg.id);

    if (!currentValue) {
      const newValue = {
        data: {
          employee: msg.lookup,
          position: {},
        },
        id: msg.id,
        version: msg.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
      return;
    }

    if (msg.lookup) {
      const newValue = {
        data: {
          ...currentValue?.data,
          employee: msg.lookup,
        },
        id: msg.id,
        version: msg.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
    }
  }

  private handlePositionUpsert(msg: WsIcUpsertedPositionItem) {
    const currentValue = this.valuesMap().get(msg.employeeId);

    if (!currentValue) {
      const newValue = {
        data: {
          employee: {},
          position: msg.lookup,
        },
        id: msg.employeeId,
        version: msg.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
      return;
    }

    // Position uses different version from employees - do not check it
    if (msg.lookup) {
      const newValue = {
        data: {
          ...currentValue?.data,
          position: msg.lookup,
        },
        id: msg.employeeId,
        version: currentValue.version,
      };

      this.upsertCacheItem(IcLookupEmployeeItemDto.fromJS(newValue));
    }
  }

  private updateSalaries(
    item: IcLookupEmployeeItemDto,
    workTimeAgreements: ReadonlyMap<string, IcLookupWtaItemDto>,
  ) {
    if (item.data.position.workTimeAgreementId) {
      const wta = workTimeAgreements.get(item.data.position.workTimeAgreementId);
      if (wta) {
        const calculatedSalaries = calculateSalariesUsingIcLookupPositionPayDto(
          item.data.position.pay,
          getTimelineItemAtDate(wta.data.normalWeeklyHoursTimeline, item.data.position.pay.from)
            ?.value.hourlyWage,
        );
        item.data.position.pay = new IcLookupPositionPayDto({
          ...item.data.position.pay,
          ...calculatedSalaries,
        });
      }
    }
  }
}
