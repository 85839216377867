import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupTagItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcTagsService extends IcServiceBase<IcLookupTagItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.Tag;

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupTagItemDto[] {
    return lookup.tags;
  }
}
