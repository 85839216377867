import { IIcLookupWtaItemDto, IcLookupWtaItemDto } from '@data-access/bulk-operations-api';

import { createIcServiceMock } from './item-cache-service.base.mock';

const icWorkTimeAgreementsMockData: IcLookupWtaItemDto[] = [
  IcLookupWtaItemDto.fromJS({
    data: {
      name: 'Normal working hours - 37.5 h/week',
      normalWeeklyHoursTimeline: [
        {
          value: {
            hourlyWage: '37.5',
            overtimeBasis: '37.5',
          },
        },
      ],
    },
    version: 1,
    id: 'id-wta-normal-37.5',
  } as IIcLookupWtaItemDto),
  IcLookupWtaItemDto.fromJS({
    data: {
      name: 'Normal working hours - 40 h/week',
      normalWeeklyHoursTimeline: [
        {
          value: {
            hourlyWage: '40',
            overtimeBasis: '40',
          },
        },
      ],
    },
    version: 1,
    id: 'id-wta-normal-40',
  } as IIcLookupWtaItemDto),
];

export function getIcWorkTimeAgreementsServiceMock() {
  return createIcServiceMock(icWorkTimeAgreementsMockData);
}
