import { Injectable, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  CostUnitTypeValuesResponseDto,
  CostUnitsClient,
  IcLookupCostUnitValueItemDto,
  IcLookupTypeEnumDto,
  ItemCacheClient,
} from '@data-access/bulk-operations-api';
import { WebSocketClient, WsServerPayloadEnum } from '@data-access/bulk-operations-ws';
import {
  EMPTY,
  Observable,
  delayWhen,
  expand,
  filter,
  iif,
  map,
  of,
  reduce,
  take,
  tap,
} from 'rxjs';

import { lookupReducer } from '../helpers/lookup-reducer';

@Injectable({ providedIn: 'root' })
export class IcCostUnitValuesService {
  protected readonly client = inject(ItemCacheClient);
  protected readonly wsClient = inject(WebSocketClient);
  protected readonly costUnitsClient = inject(CostUnitsClient);

  public readonly isInitialized = signal(false);
  protected readonly isInitialized$ = toObservable(this.isInitialized);

  private isManualSync = false;
  private readonly isLoadingSignal = signal(false);
  public readonly isLoading = this.isLoadingSignal.asReadonly();

  // Make sure to return new object on updates
  private readonly rawValues = signal<IcCostUnitValuesByType>({});
  public readonly valuesGroupedByType = this.rawValues.asReadonly();

  constructor() {
    this.subscribeToWsSyncComplete();
    this.subscribeToWsUpsert();
    this.subscribeToWsDelete();
  }

  public getValuesForType(typeId: string) {
    return computed(() => {
      return this.valuesGroupedByType()[typeId] ?? new Map();
    });
  }

  public setCache(lookupValues: IcLookupCostUnitValueItemDto[], isInitialization = false) {
    const cuvByType = {} as IcCostUnitValuesByType;

    lookupValues.forEach((item) => {
      if (!cuvByType[item.typeId]) {
        cuvByType[item.typeId] = new Map();
      }
      cuvByType[item.typeId].set(item.valueId, item);
    });

    this.rawValues.set(cuvByType);

    if (!isInitialization) {
      this.isInitialized.set(true);
    }
  }

  public upsertCacheItem(newValue: IcLookupCostUnitValueItemDto) {
    this.upsertCacheItems([newValue]);
  }

  public upsertCacheItems(newValues: IcLookupCostUnitValueItemDto[]) {
    this.rawValues.update((values) => {
      newValues.forEach((item) => {
        if (!values[item.typeId]) {
          values[item.typeId] = new Map();
        }
        values[item.typeId].set(item.valueId, item);
      });

      return { ...values };
    });
  }

  public removeCacheItems(ids: { id: string; id2?: string }[]) {
    this.rawValues.update((values) => {
      ids.forEach((item) => {
        if (!item.id2) {
          delete values[item.id];
        } else {
          values[item.id]?.delete(item.id2);
        }
      });

      return { ...values };
    });
  }

  public removeCacheItem(costUnitTypeId: string, costUnitValueId?: string) {
    this.rawValues.update((values) => {
      if (!costUnitValueId) {
        delete values[costUnitTypeId];
      } else {
        values[costUnitTypeId]?.delete(costUnitValueId);
      }

      return { ...values };
    });
  }

  public startSync() {
    this.client
      .getIcStartSync(IcLookupTypeEnumDto.Cuv)
      .pipe(take(1))
      .subscribe((resp) => {
        this.isManualSync = true;
      });
  }

  public fetchValues(): Observable<IcLookupCostUnitValueItemDto[]> {
    const observable$ = this.client.getIcLookup(IcLookupTypeEnumDto.Cuv).pipe(
      take(1),
      expand((response) =>
        response.lookup.nextToken
          ? this.client.getIcLookup(IcLookupTypeEnumDto.Cuv, response.lookup.nextToken)
          : EMPTY,
      ),
      reduce(lookupReducer),
      map((response) => response.lookup.costUnitValues),
    );

    observable$.subscribe((data) => {
      this.setCache(data);
    });

    return observable$;
  }

  protected subscribeToWsUpsert() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Cuv),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        this.upsertCacheItems(msg.items);
      });
  }

  private directFetchValues(
    costUnitTypeId: string,
    search?: string,
  ): Observable<IcLookupCostUnitValueItemDto[]> {
    this.isLoadingSignal.set(true);
    const observable$ = this.costUnitsClient.getCostUnitTypeValues(costUnitTypeId, search).pipe(
      expand((response) => {
        return response.valuesForType?.cursor
          ? this.costUnitsClient.getCostUnitTypeValues(
              costUnitTypeId,
              search,
              response.valuesForType.cursor,
            )
          : EMPTY;
      }),
      reduce((acc, value) => {
        if (!acc || !acc.valuesForType) return value;

        acc.valuesForType.costUnitValues = acc.valuesForType.costUnitValues.concat(
          value.valuesForType?.costUnitValues ?? [],
        );

        return acc;
      }),
      take(1),
      map((response) => this.mapToIcCostUnitValueItemDto(response)),
      tap(() => this.isLoadingSignal.set(false)),
    );

    observable$.subscribe((data) => {
      this.upsertCacheItems(data);
    });

    return observable$;
  }

  private subscribeToWsSyncComplete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcSyncCompleted)
      .pipe(filter((msg) => msg.type === IcLookupTypeEnumDto.Cuv))
      .subscribe((msg) => {
        if (msg.isInitialization || this.isManualSync) {
          this.fetchValues();
        }
      });
  }

  private subscribeToWsDelete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcDeleted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Cuv),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        console.log(`[Delete] ${IcLookupTypeEnumDto.Cuv}`, msg);
        this.removeCacheItems(msg.items);
      });
  }

  private mapToIcCostUnitValueItemDto(
    response: CostUnitTypeValuesResponseDto,
  ): IcLookupCostUnitValueItemDto[] {
    if (!response.valuesForType) {
      return [];
    }
    const typeId = response.valuesForType.typeCustomId;

    return response.valuesForType.costUnitValues.map((value) => {
      return new IcLookupCostUnitValueItemDto({
        typeId,
        valueId: value.customId,
        name: value.name,
        from: value.from,
        to: value.to,
      });
    });
  }
}

type IcCostUnitValuesByType = Record<string, Map<string, IcLookupCostUnitValueItemDto>>;
