import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupEventCodeForAbsenceRegistrationItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcEventCodesForAbsenceRegistrationService extends IcServiceBase<IcLookupEventCodeForAbsenceRegistrationItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.EventCodeForAr;

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupEventCodeForAbsenceRegistrationItemDto[] {
    return lookup.eventCodesForAbsenceRegistration;
  }
}
