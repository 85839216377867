import { Injectable } from '@angular/core';
import {
  IcLookupCreditorItemDto,
  IcLookupDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcCreditorsService extends IcServiceBase<IcLookupCreditorItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.Creditor;

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupCreditorItemDto[] {
    return lookup.creditors;
  }
}
