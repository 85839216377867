import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupPaycodeForFixedTransactionItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPaycodesForFixedTransactionService extends IcServiceBase<IcLookupPaycodeForFixedTransactionItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.PaycodeForFt;

  protected override getValuesFromLookupDto(
    lookup: IcLookupDto,
  ): IcLookupPaycodeForFixedTransactionItemDto[] {
    return lookup.paycodesForFixedTransaction;
  }
}
