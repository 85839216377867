import { Signal, computed, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  IcLookupCarItemDto,
  IcLookupCostUnitTypeItemDto,
  IcLookupCreditorItemDto,
  IcLookupDto,
  IcLookupEmployeeItemDto,
  IcLookupEventCodeForAbsenceRegistrationItemDto,
  IcLookupPaycodeForExtraPaymentItemDto,
  IcLookupPaycodeForFixedTransactionItemDto,
  IcLookupPensionCompanyItemDto,
  IcLookupTagItemDto,
  IcLookupTaxUnitItemDto,
  IcLookupTypeEnumDto,
  IcLookupUnionItemDto,
  IcLookupWtaItemDto,
  ItemCacheClient,
} from '@data-access/bulk-operations-api';
import {
  WebSocketClient,
  WsIcUpserted,
  WsServerPayloadEnum,
} from '@data-access/bulk-operations-ws';
import { EMPTY, Subscription, delayWhen, expand, filter, iif, of, reduce, take } from 'rxjs';

import { lookupReducer } from '../helpers/lookup-reducer';

export abstract class IcServiceBase<TItem extends IcGenericLookupTypes> {
  protected abstract readonly lookupType: IcLookupTypeEnumDto;

  protected readonly client = inject(ItemCacheClient);
  protected readonly wsClient = inject(WebSocketClient);

  public readonly isInitialized = signal(false);
  protected readonly isInitialized$ = toObservable(this.isInitialized);

  private isManualSync = false;

  // Make sure to return new Map on updates
  protected readonly rawValues = signal<Map<string, TItem>>(new Map());
  public readonly valuesMap = this.rawValues.asReadonly() as Signal<ReadonlyMap<string, TItem>>;
  public readonly values = computed<TItem[]>(() => [...this.rawValues().values()]);

  protected wsUpsertSubscription: Subscription | undefined = undefined;

  constructor() {
    this.subscribeToWsSyncComplete();
    this.subscribeToWsUpsert();
    this.subscribeToWsDelete();
  }

  public setCache(lookupValues: TItem[], isInitializing = false) {
    this.rawValues.set(new Map(lookupValues.map((item) => [item.id, item])));
    if (!isInitializing) {
      this.isInitialized.set(true);
    }
  }

  public upsertCacheItems(newValues: TItem[]) {
    this.rawValues.update((values) => {
      newValues.forEach((newValue) => {
        const currentValue = values.get(newValue.id);

        // We will remove version if there are no errors
        if (currentValue && currentValue.version > newValue.version) {
          console.error(
            `ItemCache: [${this.lookupType}] received WS message with out-of-date version. ID: ${newValue.id}, v: ${newValue.version} (v: ${currentValue.version})`,
          );
        }

        values.set(newValue.id, newValue);
      });

      return new Map(values);
    });
  }

  public upsertCacheItem(newValue: TItem) {
    this.upsertCacheItems([newValue]);
  }

  public removeCacheItems(ids: string[]) {
    this.rawValues.update((values) => {
      ids.forEach((id) => values.delete(id));

      return new Map(values);
    });
  }

  public removeCacheItem(id: string) {
    this.rawValues.update((values) => {
      values.delete(id);
      return new Map(values);
    });
  }

  public startSync() {
    this.client
      .getIcStartSync(this.lookupType)
      .pipe(take(1))
      .subscribe((resp) => {
        this.isManualSync = true;
      });
  }

  public fetchValues() {
    this.client
      .getIcLookup(this.lookupType)
      .pipe(
        take(1),
        expand((response) =>
          response.lookup.nextToken
            ? this.client.getIcLookup(this.lookupType, response.lookup.nextToken)
            : EMPTY,
        ),
        reduce(lookupReducer),
      )
      .subscribe((data) => {
        this.setCache(this.getValuesFromLookupDto(data.lookup));
      });
  }

  protected abstract getValuesFromLookupDto(lookup: IcLookupDto): TItem[];

  protected subscribeToWsUpsert() {
    this.wsUpsertSubscription = this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(
        filter((msg): msg is WsIcGenericTypes => msg.type === this.lookupType),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        this.upsertCacheItems(msg.items as unknown as TItem[]);
      });
  }

  private subscribeToWsSyncComplete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcSyncCompleted)
      .pipe(filter((msg) => msg.type === this.lookupType))
      .subscribe((msg) => {
        if (msg.isInitialization || this.isManualSync) {
          this.fetchValues();
        }
      });
  }

  private subscribeToWsDelete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcDeleted)
      .pipe(
        filter((msg) => msg.type === this.lookupType),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        this.removeCacheItems(msg.items.map((item) => item.id));
      });
  }
}

export type IcGenericLookupTypes =
  | IcLookupEmployeeItemDto // Some methods are override in child classes
  | IcLookupTaxUnitItemDto
  | IcLookupCreditorItemDto
  | IcLookupUnionItemDto
  | IcLookupPensionCompanyItemDto
  | IcLookupWtaItemDto
  | IcLookupCarItemDto
  | IcLookupTagItemDto
  | IcLookupPaycodeForExtraPaymentItemDto
  | IcLookupPaycodeForFixedTransactionItemDto
  | IcLookupEventCodeForAbsenceRegistrationItemDto
  | IcLookupCostUnitTypeItemDto;

type WsIcGenericTypes = WsIcUpserted & { items: IcGenericLookupTypes[] };
