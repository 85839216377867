import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupTypeEnumDto,
  IcLookupWtaItemDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcWorkTimeAgreementsService extends IcServiceBase<IcLookupWtaItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.Wta;

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupWtaItemDto[] {
    return lookup.wtas;
  }
}
