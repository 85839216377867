import { Injectable, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  IIcLookupSettingsDto,
  IcLookupSettingsDto,
  IcLookupTypeEnumDto,
  ItemCacheClient,
} from '@data-access/bulk-operations-api';
import { WebSocketClient, WsServerPayloadEnum } from '@data-access/bulk-operations-ws';
import { EMPTY, delayWhen, expand, filter, iif, of, reduce, take, tap } from 'rxjs';

import { lookupReducer } from '../helpers/lookup-reducer';

@Injectable({ providedIn: 'root' })
export class IcSettingsService {
  protected readonly client = inject(ItemCacheClient);
  protected readonly wsClient = inject(WebSocketClient);

  public readonly isInitialized = signal(false);
  protected readonly isInitialized$ = toObservable(this.isInitialized);

  private isManualSync = false;

  private readonly valueSignal = signal<IIcLookupSettingsDto>(new IcLookupSettingsDto());
  public readonly value = this.valueSignal.asReadonly();

  constructor() {
    this.subscribeToWsSyncComplete();
    this.subscribeToWsUpsert();
  }

  public setCache(lookupValue: IIcLookupSettingsDto, isInitialization = false) {
    this.valueSignal.set(lookupValue);
    if (!isInitialization) {
      this.isInitialized.set(true);
    }
  }

  public startSync() {
    this.client
      .getIcStartSync(IcLookupTypeEnumDto.Settings)
      .pipe(take(1))
      .subscribe(() => {
        this.isManualSync = true;
      });
  }

  public fetchValues() {
    this.client
      .getIcLookup(IcLookupTypeEnumDto.Settings)
      .pipe(
        take(1),
        expand((response) =>
          response.lookup.nextToken
            ? this.client.getIcLookup(IcLookupTypeEnumDto.Settings, response.lookup.nextToken)
            : EMPTY,
        ),
        reduce(lookupReducer),
      )
      .subscribe((data) => {
        this.setCache(data.lookup.settings);
      });
  }

  protected subscribeToWsUpsert() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcUpserted)
      .pipe(
        filter((msg) => msg.type === IcLookupTypeEnumDto.Settings),
        // Hold processing to avoid overwrites
        delayWhen(() =>
          iif(
            () => this.isInitialized(),
            of(true),
            this.isInitialized$.pipe(filter((isInitialized) => isInitialized)),
          ),
        ),
      )
      .subscribe((msg) => {
        this.setCache(msg.item);
      });
  }

  private subscribeToWsSyncComplete() {
    this.wsClient
      .getMessagesByType(WsServerPayloadEnum.IcSyncCompleted)
      .pipe(filter((msg) => msg.type === IcLookupTypeEnumDto.Settings))
      .subscribe((msg) => {
        if (msg.isInitialization || this.isManualSync) {
          this.fetchValues();
        }
      });
  }
}
