import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupPensionCompanyItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcPensionCompaniesService extends IcServiceBase<IcLookupPensionCompanyItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.PensionCompany;

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupPensionCompanyItemDto[] {
    return lookup.pensionCompanies;
  }
}
