import { Injectable } from '@angular/core';
import {
  IcLookupDto,
  IcLookupTaxUnitItemDto,
  IcLookupTypeEnumDto,
} from '@data-access/bulk-operations-api';

import { IcServiceBase } from './ic-service.base';

@Injectable({ providedIn: 'root' })
export class IcTaxUnitsService extends IcServiceBase<IcLookupTaxUnitItemDto> {
  protected override lookupType = IcLookupTypeEnumDto.TaxUnit;

  protected override getValuesFromLookupDto(lookup: IcLookupDto): IcLookupTaxUnitItemDto[] {
    return lookup.taxUnits;
  }
}
